import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = ({ location }) => (
	<Layout location={location}>
		<main>
			<h1>Not Found</h1>
			<p>I haven’t written this post yet. Maybe its time hasn’t come yet?</p>
		</main>
	</Layout>
);


export default NotFoundPage;
